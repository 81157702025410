<template>
  <div id="ticketAvailableDialog">
    <el-dialog
      title="发放金额"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="80px"
      >
        <el-form-item label="金额" prop="amount">
          <el-input
            v-model.number="addForm.amount"
            placeholder="请输入金额"
          ></el-input>
        </el-form-item>
        <el-form-item label="类型" prop="chargeType">
          <el-radio-group v-model="addForm.chargeType">
            <el-radio label="充值"></el-radio>
            <el-radio label="扣款"></el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getInfo"],
  data() {
    return {
      show: false,
      loading: false,
      addForm: {
        amount: "",
        chargeType: "充值",
      },
      addFormRules: {
        amount: [
          { required: true, message: "请输入金额", trigger: "blur" },
          { type: "number", message: "金额必须为数字值" },
        ],
        chargeType: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.$set(this, "addForm", {
        amount: "",
        chargeType: "充值",
      });
      this.$refs["addForm"].resetFields();
    },
    async confirmBtn() {
      let falg = true;
      this.$refs["addForm"].validate((valid) => {
        if (!valid) {
          falg = false;
        }
      });
      if (!falg) {
        return;
      }
      let amount = this.addForm.amount;
      if (amount <= 0) {
        this.$message.warning("金额必须为大于0的数字");
        return;
      }
      if (this.addForm.chargeType !== "充值") {
        amount = "-" + amount;
      }
      try {
        this.loading = true;

        let res = await this.$http.post("/parking/shop/charge", {
          amount,
          userId: this.$route.params.id,
        });
        if (res.code === 0) {
          this.getInfo();
          this.$message.success("操作成功");
          this.show = false;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#ticketAvailableDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
